import clsx from 'clsx'
import Script from 'next/script'
import * as React from 'react'
import { useState } from 'react'
import Modal from 'react-modal'
import type { HeaderLocaleResult } from '../data/HeaderLocaleFragment'
import type { NewsletterFormLocaleResult } from '../data/NewsletterForm'
import { isLinkPresentInParentPages } from '../utilities/isLinkPresentInParentPages'
import { useOnNavigationFinished } from '../utilities/useOnNavigationFinished'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'
import { NewsletterModal } from './NewsletterModal'

export type HeaderProps = {
	header: HeaderLocaleResult
	newsletterModal?: NewsletterFormLocaleResult
	parentPages?: Array<{ url: string; title?: string }>
	resultPageUrl?: string
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)

	const [isModalOpen, setIsModalOpen] = useState(false)

	useOnNavigationFinished(() => {
		setOpen(false)
		setIsModalOpen(false)
	})

	const customStyles = {
		content: {
			maxWidth: '650px',
			maxHeight: '500px',
			border: 'none',
			borderRadius: '0',
			margin: 'auto',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
	}

	return (
		<>
			<Container size="wide">
				<div className={s.Wrapper}>
					<button className={s.Burger} onClick={() => setOpen((old) => !old)}>
						<Icon name="burger" />
					</button>
					{props.header.root?.logo && (
						<div className={s.Column}>
							<a href="/" className={clsx(s.Logo)}>
								<ContemberImage image={props.header.root.logo} objectFit="contain" layout="fill" />
							</a>
						</div>
					)}
					<div className={clsx(s.Column, s.SpecialLinkWrapper)}>
						<Link className={s.SpecialLink} link={props.header.specialLink} />
						<Script src="https://cse.google.com/cse.js?cx=326a31438cde249d4" strategy="afterInteractive" />
						<div className={s.SearchWrapperDesktop}>
							<div className="gcse-searchbox-only" data-resultsUrl={props.resultPageUrl} />
						</div>
					</div>
				</div>
			</Container>
			<div className={clsx(s.MobileHeader, open && s.MobileHeaderOpen)}>
				<button className={s.Close} onClick={() => setOpen((old) => !old)}>
					<Icon name="cross" />
				</button>
				<div className={s.SearchWrapperMobile}>
					<div className="gcse-searchbox-only" data-resultsUrl={props.resultPageUrl} />
				</div>
				<div className={s.Links}>
					{props.header.links?.items.map((item) => {
						const isActive = isLinkPresentInParentPages(props.parentPages, item?.link?.internalLink?.url)
						return (
							<div key={item.id}>
								<Link className={clsx(s.Link, isActive && s.is_active)} link={item.link} />
								{item.link?.internalLink?.genericPage?.root?.childPages &&
									item.link?.internalLink?.genericPage?.root?.childPages.length >= 1 && (
										<div className={clsx(s.Links, s.LinksSecondLevel)}>
											{item.link?.internalLink?.genericPage?.root?.childPages.map((item) => {
												const isActiveSecondLevel = isLinkPresentInParentPages(
													props.parentPages,
													item.localesByLocale?.link?.url,
												)
												return (
													<React.Fragment key={item.id}>
														<Link
															href={item.localesByLocale?.link?.url ?? ''}
															className={clsx(s.LinkSecondLevel, isActiveSecondLevel && s.is_active)}
															activeClassName={s.is_active}
														>
															{item.localesByLocale?.title}
														</Link>
														<div className={clsx(s.Links, s.LinksThirdLevel)}>
															{item.localesByLocale?.link?.genericPage?.root?.childPages.map((item) => {
																const isActiveThirdLevel = isLinkPresentInParentPages(
																	props.parentPages,
																	item.localesByLocale?.link?.url,
																)
																return (
																	<Link
																		key={item.id}
																		href={item.localesByLocale?.link?.url ?? ''}
																		className={clsx(s.LinkThirdLevel, isActiveThirdLevel && s.is_active)}
																	>
																		{item.localesByLocale?.title}
																	</Link>
																)
															})}
														</div>
													</React.Fragment>
												)
											})}
										</div>
									)}
							</div>
						)
					})}
				</div>

				<Link className={s.SpecialLinkMobile} link={props.header.specialLink} />
				<div
					onClick={() => {
						setIsModalOpen(true)
						setOpen(false)
					}}
					className={s.NewsletterLabelMobile}
				>
					{props.header.newsletterLabel}
				</div>
				<div className={s.Socials}>
					{props.header.facebook && (
						<Link className={s.Social} href={props.header.facebook}>
							<Icon name="facebook" />
						</Link>
					)}
					{props.header.linkedin && (
						<Link className={s.Social} href={props.header.linkedin}>
							<Icon name="linkedin" />
						</Link>
					)}
					{props.header.twitter && (
						<Link className={s.Social} href={props.header.twitter}>
							<Icon name="twitter" />
						</Link>
					)}
					{props.header.email && (
						<Link className={s.Social} href={props.header.email}>
							<Icon name="email" />
						</Link>
					)}
				</div>
			</div>
			<div className={s.LinksWrapper}>
				<Container size="wide">
					<div className={s.LinksWrapperIn}>
						<div className={s.Socials}>
							{props.header.facebook && (
								<Link className={s.Social} href={props.header.facebook}>
									<Icon name="facebook" />
								</Link>
							)}
							{props.header.linkedin && (
								<Link className={s.Social} href={props.header.linkedin}>
									<Icon name="linkedin" />
								</Link>
							)}
							{props.header.twitter && (
								<Link className={s.Social} href={props.header.twitter}>
									<Icon name="twitter" />
								</Link>
							)}
							{props.header.email && (
								<Link className={s.Social} href={props.header.email}>
									<Icon name="email" />
								</Link>
							)}
						</div>
						<div className={s.Links}>
							{props.header.links?.items.map((item) => {
								const isActive = Boolean(props.parentPages?.find((page) => page.url === item.link?.internalLink?.url))
								return <Link key={item.link?.id} className={clsx(s.Link, isActive && s.is_active)} link={item.link} />
							})}
						</div>

						<div onClick={() => setIsModalOpen(true)} className={s.NewsletterLabel}>
							{props.header.newsletterLabel}
						</div>
						{props.newsletterModal && (
							<Modal isOpen={isModalOpen} style={customStyles}>
								<NewsletterModal
									newsletterModal={props.newsletterModal}
									onRequestClose={() => {
										setIsModalOpen(false)
									}}
								/>
							</Modal>
						)}
					</div>
				</Container>
			</div>
		</>
	)
}
