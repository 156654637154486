import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { useActivePage } from '../../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/LinkFragment'

export type LinkProps = {
	className?: string
	activeClassName?: string
	children?: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
	fake?: boolean
	isTargetBlank?: boolean
} & ({ link: LinkResult | undefined } | { href: string })

export function Link(props: LinkProps) {
	const { activeClassName, ...rest } = props
	const activeUrl = useActivePage()

	if ('href' in props) {
		const isActive = activeUrl === props.href
		return (
			<NextLink
				{...rest}
				href={props.href}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={props.onClick}
				target={props.isTargetBlank ? '_blank' : ''}
			>
				{props.children}
			</NextLink>
		)
	}
	if (props.link?.externalLink || props.link?.internalLink) {
		const url =
			props.link.type === 'internal' && props.link.internalLink?.url
				? props.link.internalLink.url
				: props.link.type === 'external' && props.link.externalLink

		const isActive = activeUrl === url

		if (typeof url !== 'string') {
			return null
		}
		return (
			<NextLink
				{...rest}
				href={url}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={props.onClick}
				target={props.isTargetBlank ? '_blank' : ''}
			>
				{props.children ?? props.link.title}
			</NextLink>
		)
	} else {
		return null
	}
}
