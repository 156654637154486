// Reexport all svg icons
export { default as arrow } from './arrow.svg'
export { default as burger } from './burger.svg'
export { default as cross } from './cross.svg'
export { default as email } from './email.svg'
export { default as facebook } from './facebook.svg'
export { default as linkedin } from './linkedin.svg'
export { default as search } from './search.svg'
export { default as share } from './share.svg'
export { default as twitter } from './twitter.svg'
