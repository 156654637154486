import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './FlashMessage.module.sass'

export type FlashMessageProps = {
	type: 'neutral' | 'error' | 'success'
	children: React.ReactNode
}

export const FlashMessage: FunctionComponent<FlashMessageProps> = ({ type, children }) => {
	return <div className={clsx(styles.wrapper, styles[`view-type-${type}`])}>{children}</div>
}
