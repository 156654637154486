import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { CookieBarLocaleResult } from '../data/CookieBarLocaleFragment'
import { Container } from './Container'
import s from './CookieBar.module.sass'
import { Icon } from './Icon'

export type CookieBarProps = {
	cookieBar: CookieBarLocaleResult
	isCookieBarOpen: boolean
	setIsCookieBarOpen: (open: boolean) => void
}

export const CookieBar: FunctionComponent<CookieBarProps> = ({ cookieBar, isCookieBarOpen, setIsCookieBarOpen }) => {
	const [cookiesDefault, setCookiesDefault] = useState(true)
	const [cookiesSettingsOpen, setCookiesSettingsOpen] = useState(false)

	const reloadCookieSettings = useCallback(() => {
		const result = {
			technical: window.localStorage.getItem('cookies-technical'),
			analytical: window.localStorage.getItem('cookies-analytical'),
		}

		setIsCookieBarOpen(!result.technical)

		// if (result.analytical) {
		// 	gtag('consent', 'update', {
		// 		analytics_storage: 'granted',
		// 	})
		// }
		return result
	}, [setIsCookieBarOpen])

	const agreeWithAllCookies = () => {
		window.localStorage.setItem('cookies-technical', new Date().toISOString())
		window.localStorage.setItem('cookies-analytical', new Date().toISOString())
		reloadCookieSettings()
	}

	const agreeWithCustomCookies = (options: { technical: boolean; analytical: boolean }) => {
		window.localStorage.setItem('cookies-technical', new Date().toISOString())

		if (options.analytical) {
			window.localStorage.setItem('cookies-analytical', new Date().toISOString())
		} else {
			window.localStorage.removeItem('cookies-analytical')
		}

		reloadCookieSettings()
	}

	useEffect(() => {
		reloadCookieSettings()
	}, [reloadCookieSettings])

	return (
		<Container size="fullWidth" disableGutters>
			<div className={clsx(s.Container, isCookieBarOpen && s.is_Open)}>
				<div className={clsx(s.Wrapper)}>
					<button className={clsx(s.Btn, s.Close)} onClick={() => setIsCookieBarOpen(!isCookieBarOpen)}>
						<Icon name="cross" />
					</button>
					<div className={clsx(s.CookiesDefault, cookiesDefault && s.is_Open)}>
						<h3 className={s.Title}>{cookieBar.cookieTitle}</h3>
						{cookieBar.cookieText && (
							<div className={s.Text}>
								<RichTextRenderer source={cookieBar.cookieText} />
							</div>
						)}
						<div className={s.Actions}>
							<button onClick={() => (setCookiesSettingsOpen(true), setCookiesDefault(false))} className={s.Btn}>
								{cookieBar.cookieSettingsBtn}
							</button>
							<button onClick={() => agreeWithAllCookies()} className={s.Btn}>
								{cookieBar.cookieConsentBtn}
							</button>
						</div>
					</div>

					<form
						className={clsx(s.CookieBarSettings, cookiesSettingsOpen && s.is_settingsOpen)}
						id="cookieBar-settings"
						onSubmit={(e) => {
							e.preventDefault()
							const data = new FormData(e.currentTarget)
							agreeWithCustomCookies({
								technical: !!data.get('technical'),
								analytical: !!data.get('analytical'),
							})
						}}
					>
						<div className={s.InputWrapper}>
							<label className={s.Input}>
								<input type="checkbox" name="technical" checked onChange={() => undefined} />
								{cookieBar.necessaryCookiesTitle}
							</label>
							{cookieBar.necessaryCookiesText && (
								<div className={s.InputText}>
									<RichTextRenderer source={cookieBar.necessaryCookiesText} />
								</div>
							)}
						</div>
						<div className={s.InputWrapper}>
							<label className={s.Input}>
								<input type="checkbox" name="analytical" />
								{cookieBar.analyticCookiesTitle}
							</label>
							{cookieBar.analyticCookiesText && (
								<p className={s.InputText}>
									<RichTextRenderer source={cookieBar.analyticCookiesText} />
								</p>
							)}
						</div>
						<button type="submit" className={s.Btn}>
							{cookieBar.cookiePartialConsent}
						</button>
					</form>
				</div>
			</div>
		</Container>
	)
}
