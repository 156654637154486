import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import { useState } from 'react'
import Modal from 'react-modal'
import call from '../../public/images/call.jpg'
import type { FooterLocaleResult } from '../data/FooterLocaleFragment'
import type { NewsletterFormLocaleResult } from '../data/NewsletterForm'
import { useOnNavigationFinished } from '../utilities/useOnNavigationFinished'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Footer.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'
import { NewsletterModal } from './NewsletterModal'

export type FooterProps = {
	footer: FooterLocaleResult
	newsletterModal?: NewsletterFormLocaleResult
	openCookieBar: () => void
	hasCallToDefendMediaBox?: boolean
}

export function Footer(props: FooterProps) {
	const [isModalOpen, setIsModalOpen] = useState(false)

	useOnNavigationFinished(() => {
		setIsModalOpen(false)
	})

	const customStyles = {
		content: {
			maxWidth: '650px',
			maxHeight: '500px',
			border: 'none',
			borderRadius: '0',
			margin: 'auto',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
	}

	return (
		<>
			<Container size="medium">
				<div className={s.Boxes}>
					{props.hasCallToDefendMediaBox && (
						<div className={s.CallToDefendMedia}>
							<Image
								className={s.CallToDefendMediaImage}
								src={call}
								alt="Call to defend media image"
								width={call.width / 2}
								height={call.height / 2}
							/>
							<Link className={s.CallToDefendMediaLink} link={props.footer.callToDefendMedia} />
						</div>
					)}
					<div className={s.SupportUs}>
						<div className={s.SupportUsSubtitle}>{props.footer.supportUsSubtitle}</div>
						<div className={s.SupportUsTitle}>{props.footer.supportUsTitle}</div>
						<div className={s.SupportUsLinks}>
							{props.footer.supportUsLinks?.items.map((item) => (
								<Link className={s.SupportUsLink} key={item.link?.id} link={item.link} />
							))}
						</div>
					</div>
				</div>
			</Container>
			<div className={s.Wrapper}>
				<Container size="wide">
					<div className={s.WrapperIn}>
						<div onClick={() => setIsModalOpen(true)} className={s.NewsletterLabel}>
							{props.footer.newsletterLabel}
						</div>
						{props.newsletterModal && (
							<Modal isOpen={isModalOpen} style={customStyles}>
								<NewsletterModal
									newsletterModal={props.newsletterModal}
									onRequestClose={() => {
										setIsModalOpen(false)
									}}
								/>
							</Modal>
						)}
						<div className={s.InfoWrapper}>
							<a href={`mailto:${props.footer.email}`} className={s.Info}>
								{props.footer.email}
							</a>
							<div className={s.Info}>{props.footer.ico}</div>
							<div className={s.Info}>{props.footer.address}</div>
							<div className={s.Info}>{props.footer.postcode}</div>
							<div className={s.Info}>{props.footer.state}</div>
						</div>
						<div className={s.BottomPartWrapper}>
							<div className={s.Column} />
							<div className={clsx(s.Column, s.LogoWrapper)}>
								{props.footer.root?.logo && (
									<a href="/" className={s.Logo}>
										<ContemberImage image={props.footer.root.logo} objectFit="contain" layout="fill" />
									</a>
								)}
								<div className={s.GdprWrapper}>
									{props.footer.gdpr && (
										<div className={s.Gdpr}>
											<RichTextRenderer source={props.footer.gdpr} />
										</div>
									)}
									{props.footer.cookies && (
										<button onClick={props.openCookieBar} className={s.Cookies}>
											{props.footer.cookies}
										</button>
									)}
								</div>
							</div>
							<div className={clsx(s.Column, s.Socials)}>
								{props.footer.facebook && (
									<Link className={s.Social} href={props.footer.facebook}>
										<Icon name="facebook" />
									</Link>
								)}
								{props.footer.linkedin && (
									<Link className={s.Social} href={props.footer.linkedin}>
										<Icon name="linkedin" />
									</Link>
								)}
								{props.footer.twitter && (
									<Link className={s.Social} href={props.footer.twitter}>
										<Icon name="twitter" />
									</Link>
								)}
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}
