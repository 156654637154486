import { useState } from 'react'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { NewsletterFormLocaleResult } from '../data/NewsletterForm'
import { api } from '../utilities/api'
import { Icon } from './Icon'
// import { Link } from './Link'
import { FlashMessage } from './FlashMessage'
import { Link } from './Link'
import s from './NewsletterModal.module.sass'

export type NewsletterModalProps = {
	newsletterModal: NewsletterFormLocaleResult
	onRequestClose?: () => void
}

export function NewsletterModal(props: NewsletterModalProps) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')

	const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined)

	const [formState, setFormState] = useState<'initial' | 'success' | 'error'>('initial')

	const mutation = api.contact.send.useMutation({
		onSuccess(data) {
			if (!data.ok) {
				setFormErrorMessage(data.errorMessage)
				setFormState('error')
			} else {
				setFormState('success')
			}
		},
		onError: () => {
			alert('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.')
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<>
			{formState === 'initial' ? (
				<form
					className={s.Wrapper}
					onSubmit={(event) => {
						event.preventDefault()
						mutation.mutate({
							firstName,
							lastName,
							email,
						})
					}}
				>
					<button className={s.Close} type="button" onClick={() => props.onRequestClose?.()}>
						<Icon name="cross" />
					</button>
					<div className={s.FormFields}>
						<label className={s.FormField}>
							{props.newsletterModal.firstName}
							<input
								className={s.Input}
								type="text"
								value={firstName}
								onChange={(event) => {
									setFirstName(event.target.value)
								}}
							/>
						</label>
						<label className={s.FormField}>
							{props.newsletterModal.lastName}
							<input
								className={s.Input}
								type="text"
								value={lastName}
								onChange={(event) => {
									setLastName(event.target.value)
								}}
							/>
						</label>
						<label className={s.FormField}>
							{props.newsletterModal.mail}
							<input
								className={s.Input}
								type="email"
								autoComplete="email"
								value={email}
								onChange={(event) => {
									setEmail(event.target.value)
								}}
							/>
						</label>
						<Link
							className={s.Gdpr}
							link={props.newsletterModal.gdpr}
							isTargetBlank={props.newsletterModal.gdpr?.isTargetBlank}
						>
							{props.newsletterModal.gdpr?.title}
						</Link>
						<button type="submit" className={s.Button}>
							{props.newsletterModal.button}
						</button>
					</div>
				</form>
			) : formState === 'success' ? (
				<>
					<button className={s.Close} type="button" onClick={() => props.onRequestClose?.()}>
						<Icon name="cross" />
					</button>
					<FlashMessage type="success">
						Těší nás, že jsou pro vás nezávislá média důležitá a rozhodli jste se odebírat náš Newsletter. Váš podpis je
						nutné potvrdit kliknutím na odkaz, který jsme vám zaslali e-mailem, děkujeme. (E-mail byste již nyní měli
						mít ve své e-mailové schránce. Pokud ho tam nevidíte, zkontrolujte prosím, zda ho nemáte v promoakcích nebo
						ve spamu.)
					</FlashMessage>
				</>
			) : (
				<FlashMessage type="neutral">
					{formErrorMessage ?? 'Něco se při odesílání pokazilo. Zkuste to znovu prosím později.'}
				</FlashMessage>
			)}
		</>
	)
}
